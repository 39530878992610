<script setup lang="ts">
import type { PropType } from 'vue';
import { FingerPrintIcon, QuestionMarkCircleIcon } from '@heroicons/vue/outline';
import { getUrlForProfile } from '~/utilities/url-slugs';
import { avatarGradientStyles } from '~/utilities/avatar-gradient';

const props = defineProps({
  profile: { type: Object as PropType<any>, required: true },
  namedClasses: { type: String as PropType<string>, default: 'font-bold text-neutral-700 dark:text-neutral-200' },
  unnamedClasses: {
    type: String as PropType<string>,
    default: 'tracking-tight font-mono text-neutral-900 dark:text-neutral-50',
  },
  avatarDimension: { type: String as PropType<string>, default: '1.75rem' },
});
const { profile, namedClasses, unnamedClasses, avatarDimension } = toRefs(props);
</script>
<template>
  <div class="inline-flex">
    <NuxtLink v-if="profile" :to="getUrlForProfile(profile)" :class="['inline-flex items-center gap-1 text-sm']">
      <div
        v-if="avatarDimension"
        :class="[
          `inline flex-shrink-0 rounded-full `,
          !profile.avatar_uri ? 'flex items-center justify-center bg-gradient-to-br' : '',
        ]"
        :style="{ ...avatarGradientStyles(profile.id), height: avatarDimension, width: avatarDimension }"
      >
        <img
          v-if="profile.avatar_uri"
          :src="profile.avatar_uri"
          loading="lazy"
          class="inline h-full w-full rounded-full object-cover"
        />
        <FingerPrintIcon v-else class="h-3/5 w-3/5 text-neutral-200" />
      </div>
      <span :class="profile.name ? namedClasses : unnamedClasses">{{
        profile?.name || profile.encoded_id.replace('did:chia:', '').substring(0, 12)
      }}</span>
    </NuxtLink>
    <div v-else>
      <div :class="['flex items-center gap-1 text-sm']">
        <div class="inline flex h-7 w-7 items-center justify-center rounded-full bg-neutral-100 dark:bg-neutral-800">
          <QuestionMarkCircleIcon class="h-5 w-5 text-neutral-400 dark:text-neutral-500" />
        </div>
        <span class="font-mono font-semibold tracking-tight text-neutral-500 dark:text-neutral-400">Unknown</span>
      </div>
    </div>
  </div>
</template>
